
(function(document,bar){
    if(!bar)return;


    var cookieBar = {
        agreeCookieName: 'cookiePolicyAgree',
        agreeValue: 'trueStoryBro',
        year: 365,
        cookiePath: '/',

        checkAgree: function () {
            var agree = this.getCookie(this.agreeCookieName);
            if (agree == this.agreeValue) {
                this.hideCookieBar();
            } else {
                this.showCookieBar();
            }
        },

        setAgree: function () {
            this.setCookie(this.agreeCookieName, this.agreeValue, this.year, this.cookiePath);
            this.fadeOutCookieBar();
        },

        hideCookieBar: function () {
            bar.style.display = "none";
        },

        showCookieBar: function () {
            bar.style.display = "block";
        },

        fadeOutCookieBar: function () {
            this.hideCookieBar();
        },

        setCookie: function (cname, cvalue, exdays, path) {
            var date = new Date();
            date.setTime(date.getTime() + (exdays * 24 * 60 * 60 * 1000));
            var expires = 'expires=' + date.toUTCString();
            document.cookie = cname + '=' + cvalue + '; ' + expires + '; path=' + path;
        },

        getCookie: function (cname) {
            var name = cname + "=";
            var ca = document.cookie.split(';');
            for (var i = 0; i < ca.length; i++) {
                var c = ca[i];
                while (c.charAt(0) == ' ') c = c.substring(1);
                if (c.indexOf(name) == 0) return c.substring(name.length, c.length);
            }
            return "";
        }

    };

    cookieBar.checkAgree();
    var el = document.getElementById('cookie-btn');
    if (el) el.addEventListener('click',cookieBar.setAgree.bind(cookieBar));

})(document, document.getElementById("cookies-bar"));
