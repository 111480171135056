/**
 * Otherwise, we can import all of Bootstrap.js:
 */
import 'popper.js';
import './bootstrap';
import 'core-js/modules/es.promise';
import 'core-js/modules/es.array.iterator';
//import SimpleParallax from 'simple-parallax-js';
import objectFitVideos from 'object-fit-videos';
import 'slick-carousel';
import './cookies';
/** @type {Nette}  */
import NetteForms from 'nette-forms';
import Viewer from 'viewerjs';
import 'viewerjs/dist/viewer.css';

NetteForms.validators.AppFormsValidators_fill = (element, arg) => Boolean(element.value = arg);

$(function () {
    const easeInCubic = (t) => t * t * t;
    const _scrollToElem = (startTime, currentTime, duration, scrollEndElemTop, startScrollOffset) => {
        const runtime = currentTime - startTime;
        let progress = runtime / duration;
        progress = Math.min(progress, 1);
        const ease = easeInCubic(progress);
        window.scroll(0, startScrollOffset + (scrollEndElemTop * ease));
        if (runtime < duration) {
            requestAnimationFrame((timestamp) => {
                const currentTime = timestamp || new Date().getTime();
                _scrollToElem(startTime, currentTime, duration, scrollEndElemTop, startScrollOffset);
            })
        }
    };

    const scrollToElem = (scrollEndElem, duration = 1200, offset = 0) => {

        // 3. and well animate to that node..
        const anim = requestAnimationFrame((timestamp) => {
            const stamp = timestamp || new Date().getTime();
            const start = stamp;

            const startScrollOffset = window.pageYOffset;
            const scrollEndElemTop = scrollEndElem.getBoundingClientRect().top + offset;

            _scrollToElem(start, stamp, duration, scrollEndElemTop, startScrollOffset);
        });
    };
    NetteForms.initOnLoad();
    $('[data-toggle="popover"]').popover();

    (($w, $d, $nav, $video) => {
        $d.on('click', '.map-content', function () {
            this.focus();/*Safari fix*/
        });
        $d.on('click', '[data-toggle-class]', function () {
            $(this).toggleClass(this.dataset.toggleClass);
        });
        $d.on('change', '.custom-file-input', function () {
            let $label = $(`label[for="${this.id}"]`);
            $label.data('original') || $label.data('original', $label.html());
            $label.html(this.files.length ? this.files[0].name : $label.data('original'));
        });
        $d.on('click', 'a[href^="#"]', function (e) {
            let el = document.querySelector(this.getAttribute('href'));
            if (el) {
                scrollToElem(el, 1000, -$nav.outerHeight());
                e.preventDefault();
            }
        });
        $('.slick-people').each((i, slick) => {
            $(slick).slick({
                dots: true,
                arrows: true,
                adaptiveHeight: false,
                centerPadding: '20%',
                centerMode: true,
                autoplay: true,
                infinite: true,
                customPaging: (slider, i) => '<div>' + (i + 1) + '/' + slider.slideCount + '</div>',
                slidesToShow: 1,
                responsive: [
                    {
                        breakpoint: 1024,
                        settings: {
                            arrows: false,
                            centerPadding: '0px',
                        }
                    }
                ]
            });
        });


        $('.clocks').each((i, el) => {
            const $el = $(el), $hour = $el.find('.hour'), $minute = $el.find('.minute'), $second = $el.find('.second');

            function updateClock() {
                const now = new Date(),
                    second = now.getSeconds() * 6,
                    minute = now.getMinutes() * 6 + second / 60,
                    hour = ((now.getHours() % 12) / 12) * 360 + 90 + minute / 12;
                $hour.css("transform", "rotate(" + hour + "deg)");
                $minute.css("transform", "rotate(" + minute + "deg)");
                $second.css("transform", "rotate(" + second + "deg)");
            }

            setInterval(updateClock, 1000);
            updateClock();
            $el.addClass('show');
        });

        $('#menu').on('show.bs.collapse', function () {
            $nav.addClass('nav-shown');
        }).on('hidden.bs.collapse', function () {
            $nav.removeClass('nav-shown');
        });
        $('main.homepage').each(() => {
            function scrollWatch() {
                let top = $w.scrollTop();
                let height = parseInt($nav.outerHeight());
                let bannerTop = parseInt($video.height());
                let scrollTop = bannerTop - height;
                $nav.toggleClass('unscrolled', top <= scrollTop);
            }

            $w.on('scroll', scrollWatch);
            scrollWatch();
        });

        $('[data-slick]').each((i, el) => {
            const $el = $(el);
            $el.slick($el.data('slick') || {});
            $el.find('img,picture').on('load', () => {
                $el.slick("setOption", null, null, true);
            })
        });
        $('video[data-sources]').each((i, video) => {
            let currentSource = null;
            let timeout;
            let sources = JSON.parse(video.dataset.sources);
            let update = () => {
                for (let source of sources) {
                    if (video.canPlayType(source.type) && (!source.media || window.matchMedia(source.media).matches)) {
                        if (source.src !== currentSource) {
                            currentSource = source.src;
                            video.src = currentSource;
                        }
                        return;
                    }
                }
            };
            $w.on('resize', () => {
                clearTimeout(timeout);
                timeout = setTimeout(update, 500);
            });
            update();
            video.play();
        });
        objectFitVideos(document.querySelectorAll('video.object-cover'));

        function detectIE() {
            return window.navigator.userAgent.search(/(MSIE|Trident|Edge)/) > -1;
        }

        if (detectIE()) {
            let timeout;

            function ieFix() {
                $('.ie-fix').each((i, el) => {
                    const $el = $(el);
                    $el.css('height', 'auto')
                        .css('display', 'block')
                        .css('overflow', 'hidden')
                        .css('height', $el.height() + 'px');
                });
            }

            $w.on('resize', () => {
                clearTimeout(timeout);
                timeout = setTimeout(ieFix, 500);
            });
            ieFix();
        }
        $('[data-viewer]').each((i, e) => {
            if (!e.viewer) {
                for (let a of e.querySelectorAll('a:not([download])')) {
                    a.addEventListener('click', (e) => e.preventDefault());
                }
                e.viewer = new Viewer(e, {
                    toolbar: false,
                    navbar: false,
                    url(image) {
                        return $(image).closest('[data-hd-image]').attr('data-hd-image') || image.src;
                    },
                });
            }
        });
        $('[data-counter]').each((i, e) => {
            let $el = $(e);
            let $counter = $('<p class="small text-muted my-2"></p>');
            $counter.insertAfter($el);
            let update = () => {
                $counter.text(`Počet znaků: ` + $el.val().length + '/' + $el.attr('maxlength'));
            };
            $(e).keyup(update);
            update();
        });
    })($(window), $(document), $('#navigation'), $('#video-container'));
});
